import { TailSpin } from "react-loader-spinner";
import colors from "assets/theme/base/colors";

const LoadingSpinner = () => {
  return (
    <TailSpin
      visible={true}
      height="50"
      width="50"
      color={colors.dozer.yellow}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

export default LoadingSpinner;
