import { useEffect, useState, useContext } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";
import packageJson from "../../../package.json";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "context";
import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";

import { DozerContext, DozerDispatchContext } from "../../context/dozer-context";
import { useAuth0 } from "@auth0/auth0-react";
import ArrowBack from "@mui/icons-material/ArrowBack";
import colors from "assets/theme/base/colors";
import { getColorsForInitials, getInitials } from "util/colors";
import DOZER_LOGO from "assets/images/logos/dozer-logo.svg";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { logout } = useAuth0();

  const dozerDispatch = useContext(DozerDispatchContext);
  const dozerContext = useContext(DozerContext);
  const { user: dozerUser } = dozerContext;

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth > breakpoints.values.sm);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const onLogout = () => {
    // logout from Auth0
    logout({ returnTo: window.location.origin });

    // remove from context
    dozerDispatch({
      type: "logoutUser",
    });
  };

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    }
  );

  function getUserAvatar(userName) {
    const initials = getInitials(userName);
    const colors = getColorsForInitials(initials);
    return (
      <Avatar
        width="2.5rem"
        height="auto"
        sx={{
          fontWeight: 500,
          fontSize: "1rem",
          bgcolor: colors.background,
          color: colors.foreground,
        }}
        variant="square"
        onClick={handleMenuOpen}
      >
        {initials}
      </Avatar>
    );
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={1.5} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
          <SoftBox width="auto" sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            {/* <Typography component="h1" variant="logo" fontWeight="bold">
              dozer
            </Typography> */}
            <SoftBox
              component="img"
              src={DOZER_LOGO}
              alt="dozer-logo"
              // position="absolute"
              // top={0}
              // left={0}
              width="100px"
              height="auto"
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <List sx={{ height: "100%" }}>
        {renderRoutes}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height={80}
          sx={{ position: "absolute", bottom: 0 }}
        >
          {/* <Button
            color="primary"
            onClick={handleMenuOpen}
            // onClick={onLogout}
          >
            Log out
          </Button> */}
          {dozerUser && getUserAvatar(`${dozerUser.firstName} ${dozerUser.lastName}`)}
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                ml: 1.5,
                borderRadius: 0,
                padding: 0,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "center" }}
            anchorOrigin={{ horizontal: "right", vertical: "center" }}
          >
            <ListItem>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: 2,
                }}
              >
                <Typography
                  variant="button"
                  textTransform="uppercase"
                  fontWeight="bold"
                  color={colors.dozer.gray.dark}
                  sx={{ width: "100%" }}
                >
                  {dozerUser && `${dozerUser.firstName} ${dozerUser.lastName}`}
                </Typography>
                <Typography
                  variant="caption"
                  textTransform="uppercase"
                  fontWeight="regular"
                  color={colors.dozer.gray.light}
                  sx={{ width: "100%" }}
                >
                  {dozerUser && dozerUser.email}
                </Typography>
              </Box>
            </ListItem>
            <Divider />
            <MenuItem onClick={onLogout} sx={{ "&.MuiMenuItem-root": { minHeight: 36 } }}>
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: 0,
                    mr: 1,
                  },
                }}
              >
                <Logout fontSize="small" />
              </ListItemIcon>
              <Typography
                variant="caption"
                textTransform="uppercase"
                fontWeight="regular"
                color={colors.dozer.gray.medium}
              >
                logout
              </Typography>
            </MenuItem>
          </Menu>
        </Grid>
      </List>

      {/* <SoftBox pt={2} my={2} mx={2}>
        <SidenavCard />
      </SoftBox> */}
      <div style={{ textAlign: "center", fontSize: "10px" }}>v. {packageJson.version}</div>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
