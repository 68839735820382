import { useState, useEffect, useRef, lazy, Suspense } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import VideoControls from "components/VideoControls/index";
import CalendarPicker from "components/CalendarPicker/index";
import { type ParamKeyValuePair, useParams, useSearchParams } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import VideoSlider from "components/VideoSlider";
import colors from "assets/theme/base/colors";
import _ from "lodash";
import LoadingSpinner from "components/LoadingSpinner";
import ActivityFeed from "components/ActivityFeed";
import { getAssetVideoHistory, getMonthlyVideoInfo, getAssetLiveVideoFeed } from "data/api/asset";
import Toast from "components/Toast";
import VideoOverlay from "components/VideoOverlay";
import {
  Asset,
  AssetEvent,
  StreamInfo,
  DozerVideoSegment,
  DozerMonthlyVideoInfo,
  ArchivedVideoUrl,
  VideoUrl,
  DozerVideoSegmentByPosition,
  AllCamerasVideoSegmentUrls,
  CameraPosition,
} from "data/models/models";
import { ListDetailViewIcon, CloseIcon } from "assets/Icons";

const VideoJS = lazy(() => import("components/VideoPlayer"));

// const LIVE_VIDEO_MOCK_RESPONSE = {
//   url: "https://b-54c7cb80.kinesisvideo.us-east-2.amazonaws.com/hls/v1/getHLSMasterPlaylist.m3u8?SessionToken=CiCrYQ_GGqVtlvxzMuSbcbf0wB6o9l-1OBug1Uaer_S13xIQFWxLfyrXZESTFBs1cKzvzxoZ800y_JA5QZ7Hgsw2MILd5gLJyioAAS38hiIga3Y-HoZWWZQxx24xOEha0l05wA6Aak9RzuLvx5ukbjA~",
//   expiration: 100,
//   videoType: "application/x-mpegURL",
//   isLive: true,
// };

/**
 * The process of fetching and playing video from kinesis:
 * 1. Asset is passed in from the parent component (AssetOverview)
 * 2. Extract the stream arns from the main video camera and the zed cameras and persist them to state
 * 3. State update to steam arns triggers the fetch to video segments and live video for all cameras: main and zeds
 * 4.
 */

export const VIDEO_ACTIONS = {
  SKIP_FORWARD: "skip_fw",
  SKIP_REWIND: "skip_rw",
};

type ComponentProps = {
  asset: Asset;
  shouldLoadVideo: boolean;
  timelineEvents: AssetEvent[];
};

type VideoStatusByCamera = {
  [key in CameraPosition | string]?: boolean;
};

type CurrentTimeByPosition = {
  [key in CameraPosition | string]?: Date;
};

const mainCamPositions = [
  CameraPosition.SPHERICAL_180_CAM,
  CameraPosition.SPHERICAL_CAM,
  CameraPosition.FIXED_FOV_CAM,
];

export const isMainCam = (position: CameraPosition) => mainCamPositions.includes(position);

const getMainCamPosition = (positions: CameraPosition[]) => {
  for (let i = 0; i <= positions.length; i++) {
    if (mainCamPositions.includes(positions[i])) {
      return positions[i];
    }
  }
};

const findStreamArnForPosition = (
  streamInfos: StreamInfo[],
  position: CameraPosition
): string | undefined =>
  streamInfos.find((cameraInfo) => cameraInfo.position === position)?.kinesisStreamArn;

const AssetVideoFeed = ({ asset, shouldLoadVideo }: ComponentProps) => {
  const [streamArns, setStreamArns] = useState<StreamInfo[]>([]); // Stream arns from all cameras
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLive, setIsLive] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(moment().startOf("day").toDate());
  const [videoSegments, setVideoSegments] = useState<DozerVideoSegmentByPosition>({});
  const [dateIndicators, setDateIndicators] = useState<DozerMonthlyVideoInfo[]>([]);
  const [loadingDateIndicators, setLoadingDateIndicators] = useState<boolean>(true);
  const [liveVideoUrl, setLiveVideoUrl] = useState<VideoUrl | ArchivedVideoUrl | undefined>(
    undefined
  ); // This needs ZED live video in the future.
  const [videoStartTimeMain, setVideoStartTimeMain] = useState<number>();
  const [videoStartTimeZed, setVideoStartTimeZed] = useState<number>();
  const [videoControlsHidden, setVideoControlsHidden] = useState<boolean>(true);
  const [videoCurrentTime, setVideoCurrentTime] = useState<Date | undefined>(undefined);
  const [playerCurrentTimes, setPlayerCurrentTimes] = useState<CurrentTimeByPosition>({}); // used for debugging
  // const [currentAssetEvent, setCurrentAssetEvent] = useState<AssetEvent | undefined>(undefined);
  const [isVideoPlaying, setVideoPlaying] = useState<VideoStatusByCamera>({}); // video player pause/unpause status by position
  const [isVideoLoading, setIsVideoLoading] = useState<VideoStatusByCamera>({}); // central control for all cameras to syncrhonize the video times.
  const [zedEvents, setZedEvents] = useState<AssetEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showHistoryPanel, setShowHistoryPanel] = useState<boolean>(false);
  const [swapPlayerPositions, setSwapPlayerPositions] = useState<boolean>(false);
  // video controls state
  const [canGoNext, setCanGoNext] = useState<boolean>(true);
  const [canGoBack, setCanGoBack] = useState<boolean>(true);
  const [canSkipForward, setCanSkipForward] = useState<boolean>(true);
  const [canSkipBackward, setCanSkipBackward] = useState<boolean>(true);

  // holds the video segments for this selected date
  const videoSegmentsRef = useRef<DozerVideoSegmentByPosition>({});
  const videoSegmentUrls = useRef<AllCamerasVideoSegmentUrls>({}); // All cameras: main and zeds

  // what's currently loaded/playing in the video players
  // currently selected urls
  const [currentZedEvent, setCurrentZedEvent] = useState<AssetEvent>();
  const currentSphericalUrl = useRef<ArchivedVideoUrl | VideoUrl>();
  const currentZedPosition = useRef<CameraPosition>();
  const currentZedUrl = useRef<ArchivedVideoUrl | VideoUrl>();
  // currently selected segment indexes
  const currentSphericalSegmentIdx = useRef<number>();
  const currentZedSegmentIdx = useRef<number>();
  // currently selected active video urls
  const activeSphericalUrlIndex = useRef<number>();
  const activeZedUrlIndex = useRef<number>();
  const shouldPlayLiveVideo = useRef(false);

  // If we are playing both zed and main cameras, this flag determines if they have both finished loading/resetting to the correct time.
  // It's used for syncing up the divergent times between the two players, only when currentZedEvent is set.
  const dualPlayersFinishedSeeking = useRef<VideoStatusByCamera>({});

  const currentDay = moment(selectedDate).clone().utc().valueOf();
  const nextDay = moment(selectedDate).clone().add(1, "d").utc().valueOf();

  const mainCamPosition: CameraPosition | undefined = getMainCamPosition(
    streamArns.map((arn) => arn.position)
  );

  const activeZedPosition = currentZedPosition.current;
  const activeZedVideoUrl = currentZedUrl.current;

  // const isChromium = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  const params = useParams();
  const { assetId } = params;

  // grab url params and set the player state to them
  useEffect(() => {
    const timestamp = searchParams.get("videoTimestamp");

    // set player to this specific date, this will trigger a data fetch for this date.
    if (timestamp) {
      setSelectedDate(moment(timestamp).startOf("day").toDate());
    } else {
      setSelectedDate(moment().startOf("day").toDate());
    }
  }, []);

  const updateUrlParams = ({
    videoCurrentTime,
    eventId,
  }: {
    videoCurrentTime?: Date;
    eventId?: string;
  }) => {
    const urlParams: [ParamKeyValuePair?] = [];

    if (videoCurrentTime) {
      urlParams.push(["videoTimestamp", moment(videoCurrentTime).toISOString(true)]);
    }

    if (eventId) {
      urlParams.push(["event", eventId]);
    }

    if (urlParams.length) {
      // @ts-ignore - we are checking length...
      setSearchParams(urlParams, {
        // replace: true,
      });
    } else {
      // clear search params
      const newSearchParams = new URLSearchParams();
      setSearchParams(newSearchParams);
    }
  };

  // updates URL params every time video current time or current Zed Event change
  useEffect(() => {
    if (videoCurrentTime && isVideoPlaying) {
      updateUrlParams({ videoCurrentTime, eventId: currentZedEvent?.id });
    }
  }, [videoCurrentTime]);

  useEffect(() => {
    if (asset && shouldLoadVideo) {
      setStreamArns(asset.videoStreamInfo);

      // Prefill all positions
      const videoPlayingByPosition: VideoStatusByCamera = {};
      asset.videoStreamInfo.forEach(({ position }) => {
        videoPlayingByPosition[position] = false;
      });

      setVideoPlaying(videoPlayingByPosition);
    }
  }, [asset]);

  // If we have events, open the side panel by default
  useEffect(() => {
    if (zedEvents?.length) {
      setShowHistoryPanel(true);
    }
  }, [zedEvents]);

  /**
   * @name fetchAllVideoSegments - makes a request to get asset video history for the main camera, and zed cameras if they exist.
   * @returns null
   */
  const fetchAllVideoSegments = async () => {
    if (!streamArns?.length || !assetId) {
      return;
    }

    setIsLoading(true);

    try {
      const { segmentsByPosition, segmentEvents } = await getAssetVideoHistory(
        assetId,
        streamArns,
        currentDay,
        nextDay
      );

      if (segmentsByPosition) {
        // this stores video segments for the selected date for all video players
        videoSegmentsRef.current = segmentsByPosition;
        setVideoSegments(segmentsByPosition);

        Object.entries(segmentsByPosition).forEach(([position, segments]) => {
          const segmentUrls: { [segmentStart: number]: ArchivedVideoUrl[] } = {};
          segments?.forEach((segment) => {
            segmentUrls[segment.start] = segment.segmentUrls;
          });

          videoSegmentUrls.current[position] = segmentUrls;
        });
      }

      if (segmentEvents?.length) {
        // extract events from segments response
        setZedEvents(segmentEvents);

        const zedEventInParams = searchParams.get("event");
        const preselectedTimeInParams = searchParams.get("videoTimestamp");

        // we are presetting the player to the url params state, set to the selected time and segments
        if (zedEventInParams && segmentEvents?.length) {
          // if there's an event selected in the url param, set that event to play
          const fullEvent = segmentEvents.find((event) => event.id === zedEventInParams);

          if (fullEvent) {
            // this will open up the zed player (if currently set in the url) and set the main camera to the correct segment as well.
            handleEventClick(fullEvent);
          } else {
            // we didn't find a match for the zed event, still play the main camera for this time.
            Toast.fire({
              title:
                "We couldn&apos;t find video for this event. Please refer to the main camera at the time of the event for relevant footage.",
              confirmButtonText: "Got it!",
            });
          }
        }

        // if we have a selected time in the params but no zed event selected, still play main cam for this time.
        if (!zedEventInParams && preselectedTimeInParams && mainCamPosition) {
          const unixTs = moment(preselectedTimeInParams).valueOf();

          const sphericalSelectedSegment = findSegmentForTime(unixTs, mainCamPosition);
          const sphericalSegmentIdx = videoSegmentsRef.current[mainCamPosition]?.findIndex(
            (segment: DozerVideoSegment) => segment.start === sphericalSelectedSegment?.start
          );
          currentSphericalSegmentIdx.current = sphericalSegmentIdx;

          updateVideoPlayer(unixTs, mainCamPosition, sphericalSelectedSegment);
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching video segments", error);
      setIsLoading(false);
    }
  };

  // Later we will support live video for Zeds. For now, we can only play live video on Main "Spherical" camera.
  // When refactoring this to also support live video on zeds, use the same strategy for network call function in getAssetVideoHistory()
  const fetchLiveVideo = async () => {
    let liveVideoUrlResponse = null;

    if (!mainCamPosition) {
      return;
    }

    const mainCameraStreamArn = findStreamArnForPosition(streamArns, mainCamPosition);

    if (!assetId || !mainCameraStreamArn) {
      return;
    }

    try {
      liveVideoUrlResponse = await getAssetLiveVideoFeed(
        assetId,
        mainCameraStreamArn,
        moment().utc().valueOf()
        // moment(selectedDate).clone().utc().valueOf()
        // isChromium
      );
    } catch (error) {
      console.log("error in getAssetLiveVideoFeed", error);
    }

    if (liveVideoUrlResponse) {
      setLiveVideoUrl(liveVideoUrlResponse); // parse this.
      setIsLive(liveVideoUrlResponse.isLive);
      shouldPlayLiveVideo.current = liveVideoUrlResponse.isLive;

      if (liveVideoUrlResponse.isLive) {
        setVideoCurrentTime(moment().toDate());
      }
    } else {
      setLiveVideoUrl(undefined);
    }
  };

  // Can this take assetId instead of streamArn? Doesn't need to be camera specific.
  // Can we have events return different results for various cameras?
  // In that case we need to have the main camera be the source of truth.
  const fetchDateIndicators = async (date: Date) => {
    let dateIndicatorsResponse;

    if (!streamArns?.length || !date) {
      setLoadingDateIndicators(false);
      return;
    }

    const hasMonthDateAlready =
      _.findIndex(dateIndicators, function (o) {
        return moment(o.dayTimestamp).toDate().getMonth() == date.getMonth();
      }) > -1;
    if (hasMonthDateAlready) {
      return;
    }

    setLoadingDateIndicators(true);

    if (assetId) {
      const mainStreamArn = streamArns.find((arn) => isMainCam(arn.position));

      if (mainStreamArn) {
        try {
          dateIndicatorsResponse = await getMonthlyVideoInfo(
            assetId,
            [mainStreamArn],
            moment(date).startOf("month").utc().valueOf(),
            moment(date).endOf("month").utc().valueOf(),
            moment.tz.guess()
          );

          if (dateIndicatorsResponse?.length) {
            // combine the date indicator resposne from all cameras into one object.
            const temp = _.unionBy(dateIndicators, dateIndicatorsResponse, "dayTimestamp");
            setDateIndicators(temp);
          }

          setLoadingDateIndicators(false);
        } catch (error) {
          console.log("fetchDateIndicators Error:", error);
        }
      }
    }
  };

  useEffect(() => {
    if (streamArns?.length) {
      setIsLoading(true);

      // check if url is preset to a specific time and event before turning on live video, otherwise it will reset it to play current live stream.
      const zedEventId = searchParams.get("event");
      const existingTimestamp = searchParams.get("videoTimestamp");
      if (!zedEventId && !existingTimestamp) {
        fetchLiveVideo();
      }
      fetchDateIndicators(moment().toDate());
    }
  }, [streamArns]);

  useEffect(() => {
    if (selectedDate && !isLive) {
      // pause all video players to remove playing video streams while data is loading.
      // TODO: set our loading state

      handlePauseResume(false);
      // clear out video streams to load correct data for new day
      if (mainCamPosition) {
        clearUrlForPosition(mainCamPosition);
      }

      if (currentZedPosition.current) {
        clearUrlForPosition(currentZedPosition.current);
      }

      // clear out zed events/activity feed
      setZedEvents([]);
      setShowHistoryPanel(false);
      setIsLoading(true);

      // fetch segments and select the first one
      fetchAllVideoSegments();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isLive) {
      setVideoCurrentTime(moment().toDate());
    }
  }, [isLive]);

  /**
   * @name verifyVideoSegmentUrlsPresent - ensures that video segment urls exist. If they don't, fetches stream again.
   * @param segment - the timeframe to check for
   * @returns - Promise.resolve() if urls exist, or fetches segment and updates videoSegmentUrls with new data.
   */
  const verifyVideoSegmentUrlsPresent = async (
    position: CameraPosition,
    segment: DozerVideoSegment
  ): Promise<null | void> => {
    if (!segment) {
      console.error("segment or position is missing!");
      return;
    }

    const segmentUrlsInCamera = videoSegmentUrls.current[position];

    if (segmentUrlsInCamera?.hasOwnProperty(segment.start)) {
      return Promise.resolve();
    } else {
      try {
        // console.log("Couldnt find segment urls, fetching video streams");
        await fetchAllVideoSegments();
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  // there is no url for this camera for the given time. Do a number of things:
  // 1) clear activeVideoUrlIndex ref for this camera
  // 2) clear currentVideoUrlsByPosition ref for this camera
  // 3) If there's no url in this time there shouldn't be an active segment, but how were they able to select it then.
  // 4) pause the playing video
  const clearUrlForPosition = (position: CameraPosition) => {
    if (!position) {
      return;
    }
    if (isMainCam(position)) {
      currentSphericalUrl.current = undefined;
      currentSphericalSegmentIdx.current = undefined;
      activeSphericalUrlIndex.current = undefined;

      setVideoStartTimeMain(-1);

      setVideoPlaying((prevVideoPlaying) => {
        const newVideoPlayingObj = {
          ...prevVideoPlaying,
          [position]: false,
        };

        return newVideoPlayingObj;
      });
    } else {
      // zed cam
      currentZedUrl.current = undefined;
      currentZedSegmentIdx.current = undefined;
      activeZedUrlIndex.current = undefined;
      currentZedPosition.current = undefined;
      setCurrentZedEvent(undefined);

      // for zeds, we need to also clear the start time of the synced main cam, because if we click on a zed again it needs to re-trigger the udpate
      setVideoStartTimeMain(-1);
      setVideoStartTimeZed(-1);

      setVideoPlaying((prevVideoPlaying) => {
        const newVideoPlayingObj = {
          ...prevVideoPlaying,
          [position]: false,
        };

        return newVideoPlayingObj;
      });
    }

    setPlayerCurrentTimes((prevPlayerCurrentTimes) => ({
      ...prevPlayerCurrentTimes,
      [position]: undefined,
    }));
  };

  // if we are playing both players, we don't want to resume unless they both finished seeking.
  const checkSafeResume = () => {
    // both player urls are set, we need to check if they finished loading
    if (currentZedPosition.current && currentSphericalUrl.current) {
      if (
        dualPlayersFinishedSeeking.current[mainCamPosition!] === true &&
        dualPlayersFinishedSeeking.current[currentZedPosition.current] === true
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  // used to sync up the video times. VideoStartTime seeks the VideoJs player to this particular time by seeking to it.
  // The time passed here is relative to the start of the url in each player, it is not the time of the day.
  // check if current urls are present and get relative times for those urls.
  const updateRelativeVideoStartTimes = (selectedTime: number, position: CameraPosition) => {
    // For spherical
    if (position === mainCamPosition) {
      if (currentSphericalUrl.current) {
        if (
          moment(selectedTime).unix() >=
            moment((currentSphericalUrl.current as ArchivedVideoUrl)?.window.from).unix() &&
          moment(selectedTime).unix() <=
            moment((currentSphericalUrl.current as ArchivedVideoUrl)?.window.to).unix()
        ) {
          const newStartTime =
            (selectedTime - (currentSphericalUrl.current as ArchivedVideoUrl).window.from) / 1000;

          setVideoStartTimeMain(newStartTime);
        }
      }
    } else {
      // For zeds
      if (currentZedUrl.current && currentZedPosition.current) {
        if (
          moment(selectedTime).unix() >=
            moment((currentZedUrl.current as ArchivedVideoUrl)?.window.from).unix() &&
          moment(selectedTime).unix() <=
            moment((currentZedUrl.current as ArchivedVideoUrl)?.window.to).unix()
        ) {
          const newStartTimeZed =
            (selectedTime - (currentZedUrl.current as ArchivedVideoUrl).window.from) / 1000;
          setVideoStartTimeZed(newStartTimeZed);
        } else {
          // no times match the current video player selected time, clear the zed.
          clearUrlForPosition(currentZedPosition.current);
        }
      }
    }
  };

  /**
   * @name updateVideoPlayer - fires when there is a user-initiated change to the video player. I.e. skipping to a different time on slider, or when a new segment is selected.
   * @param selectedTime - newly selected time
   * @param position - which video player to update
   * @param selectedSegment - the video segment the current time is in. Required if we're selecting a non-live time.
   */
  const updateVideoPlayer = (
    selectedTime: number,
    position: CameraPosition,
    selectedSegment?: DozerVideoSegment,
    isZedPair?: boolean // if this spherical cam was triggered by the zed event, we don't want to start playing it until the zed loads.
  ) => {
    // we're indicating that we're updating to live video...
    if (liveVideoUrl && shouldPlayLiveVideo.current) {
      handleGoLive();
      return;
    }

    if (selectedSegment) {
      verifyVideoSegmentUrlsPresent(position, selectedSegment).then(() => {
        const videoUrlsBySegmentForCamera = videoSegmentUrls.current[position];
        if (!videoUrlsBySegmentForCamera) {
          return;
        }
        const urls = videoUrlsBySegmentForCamera[selectedSegment.start];
        let selectedUrlIndex: number | undefined;
        // find the url we want to play for given time.

        for (let i = 0; i < urls?.length; i++) {
          const url = urls[i];

          // need to do a date moment conversion here because we could be comparing a unix date with seconds vs ms
          if (
            moment(selectedTime).unix() >= moment(url.window.from).unix() &&
            moment(selectedTime).unix() < moment(url.window.to).unix()
          ) {
            selectedUrlIndex = i;
            break;
          }
        }

        if (selectedUrlIndex === undefined) {
          clearUrlForPosition(position);
          return;
        } else {
          const selectedUrl = urls[selectedUrlIndex];

          // updates the url for the specific video player.
          if (position === mainCamPosition) {
            currentSphericalUrl.current = selectedUrl;
            activeSphericalUrlIndex.current = selectedUrlIndex;
          } else {
            currentZedUrl.current = selectedUrl;
            currentZedPosition.current = position;
            activeZedUrlIndex.current = selectedUrlIndex;
          }

          setVideoCurrentTime(moment(selectedTime).toDate());
          updateRelativeVideoStartTimes(selectedTime, position);
        }
      });
    }
  };

  // Triggered by clicking on a zed event in the player's activity feed. Pops up the zed video event in another player.
  const handleEventClick = (event: AssetEvent) => {
    // if we clicked on an event we are no longer playing live video
    shouldPlayLiveVideo.current = false;

    // pause all current video
    handlePauseResume(false);

    const eventStream = streamArns.find((arnObj) => arnObj.streamId === event.streamId);

    if (!eventStream) {
      console.log("No stream arn found");
      return;
    }

    const eventStartTimeSeconds = Math.round(event.streamTimestamp / 1000); // round this to seconds (sometimes the events are off by a few ms which causes the segment not to be found.)
    const eventStartTime = event.streamTimestamp;
    const eventPosition = eventStream.position;
    const segmentIdx = videoSegmentsRef.current[eventPosition]?.findIndex(
      (segment) =>
        eventStartTimeSeconds >= Math.round(segment.start / 1000) &&
        eventStartTimeSeconds <= Math.round(segment.end / 1000)
    );
    let segment;

    // clear current positions
    if (currentZedPosition.current) {
      clearUrlForPosition(currentZedPosition.current);
    }
    if (mainCamPosition) {
      clearUrlForPosition(mainCamPosition);
    }

    if (segmentIdx !== undefined) {
      segment = videoSegmentsRef.current[eventPosition]?.[segmentIdx];
    }

    if (!segment) {
      console.log("No segment found for event");
      return;
    }

    // we need to set the segment index here.
    currentZedSegmentIdx.current = segmentIdx;
    setCurrentZedEvent(event);

    dualPlayersFinishedSeeking.current = {
      [mainCamPosition!]: false,
      [eventPosition]: false,
    };

    // segment start time should match the event start time
    // Find the time for url and update video player(s) - this and spherical cam need to play at the same time.
    updateVideoPlayer(eventStartTime, eventPosition, segment);

    // Do the same thing for the spherical cam in order to bring it to the event time.
    if (mainCamPosition) {
      const sphericalSelectedSegment = findSegmentForTime(eventStartTime, mainCamPosition);
      const sphericalSegmentIdx = videoSegmentsRef.current[mainCamPosition]?.findIndex(
        (segment: DozerVideoSegment) => segment.start === sphericalSelectedSegment?.start
      );
      currentSphericalSegmentIdx.current = sphericalSegmentIdx;

      updateVideoPlayer(eventStartTime, mainCamPosition, sphericalSelectedSegment, true);
    }
  };

  const updateCurrentVideoSegment = (
    idxOfSelectedSegment: number,
    position: CameraPosition,
    currentTime?: number
  ) => {
    const selectedSegment = videoSegmentsRef.current[position]?.[idxOfSelectedSegment];

    if (!selectedSegment) {
      return;
    }

    if (isMainCam(position)) {
      currentSphericalSegmentIdx.current = idxOfSelectedSegment;
    } else {
      currentZedSegmentIdx.current = idxOfSelectedSegment;
    }

    if (currentTime !== undefined) {
      updateVideoPlayer(currentTime, position, selectedSegment);
    } else {
      const urlsObj = videoSegmentUrls.current[position];
      const urls = urlsObj?.[selectedSegment.start];

      if (urls) {
        updateVideoPlayer(urls[0]?.window.from, position, selectedSegment);
      }
    }
  };

  // fires when video segments first load
  // if we are pre-loading a specific time or zed event, we need to find and set to the correct segment for that time instead of the first segment
  useEffect(() => {
    if (!mainCamPosition || !videoSegments[mainCamPosition]?.length) {
      setIsLoading(false);
      return;
    }

    const zedEventInParams = searchParams.get("event");
    const preselectedTimeInParams = searchParams.get("videoTimestamp");

    // default state - set to the first main cam segment and keep zed players closed.
    if (
      videoSegments[mainCamPosition]?.length &&
      !isLive &&
      !zedEventInParams &&
      !preselectedTimeInParams
    ) {
      updateCurrentVideoSegment(0, mainCamPosition);
    }
  }, [videoSegments]);

  useEffect(() => {
    if (!mainCamPosition) {
      return;
    }
    if (liveVideoUrl && isLive) {
      updateVideoPlayer(moment().toDate().valueOf(), mainCamPosition);
    }
  }, [liveVideoUrl]);

  const handleVideoEvent = (
    event: string,
    liveVideoUrl: VideoUrl | ArchivedVideoUrl | undefined,
    position: CameraPosition
  ) => {
    switch (event) {
      case "ended": {
        if (currentSphericalUrl.current === liveVideoUrl?.url) {
          console.log("LIVE video ended, show UI");
        }
        // if this was a zed, we need to clear the position
        // for main cam, we don't want to clear it since it'll likely have other segments
        if (position !== mainCamPosition) {
          clearUrlForPosition(position);
        }
        break;
      }
      case "loadstart": {
        break;
      }
      case "canplaythrough": {
        // if a zed camera is playing, this is when we want to resume the main camera also.
        if (
          position !== mainCamPosition &&
          currentSphericalUrl.current &&
          mainCamPosition &&
          checkSafeResume()
        ) {
          setVideoPlaying((prevVideoPlaying) => {
            const newVideoPlayingObj = {
              ...prevVideoPlaying,
              [mainCamPosition]: true,
            };
            return newVideoPlayingObj;
          });
        }
        break;
      }
      case "seeking": {
        // check that both players are set (means we need to handle the syncing)
        if (mainCamPosition && activeZedPosition) {
          dualPlayersFinishedSeeking.current = {
            ...dualPlayersFinishedSeeking.current,
            [position]: false,
          };
        }
        break;
      }
      case "seeked": {
        if (mainCamPosition && activeZedPosition)
          dualPlayersFinishedSeeking.current = {
            ...dualPlayersFinishedSeeking.current,
            [position]: true,
          };
        break;
      }
      case "ready": {
        if (mainCamPosition && activeZedPosition) {
          dualPlayersFinishedSeeking.current = {
            ...dualPlayersFinishedSeeking.current,
            [position]: true,
          };
        }

        if (checkSafeResume()) {
          setVideoPlaying((prevVideoPlaying) => {
            const newVideoPlayingObj = {
              ...prevVideoPlaying,
              [position]: true,
            };
            return newVideoPlayingObj;
          });
        }
        break;
      }

      default:
        break;
    }
  };

  const handleGoLive = () => {
    if (!mainCamPosition) {
      return;
    }

    if (!liveVideoUrl) {
      return;
    }

    setIsLive(true);
    shouldPlayLiveVideo.current = true;
    setSelectedDate(moment().startOf("day").toDate());
    setVideoCurrentTime(moment().toDate());

    currentSphericalUrl.current = liveVideoUrl;

    setVideoStartTimeMain(-1);

    // Only play main cam and set all Zed players to false
    setVideoPlaying((prevVideoPlaying) => {
      const newVideoPlayingObj: VideoStatusByCamera = {};
      // pause the zeds
      Object.keys(prevVideoPlaying).forEach((cameraPos) => {
        if (cameraPos !== mainCamPosition) {
          newVideoPlayingObj[cameraPos] = false;
        }
      });

      newVideoPlayingObj[mainCamPosition] = true;

      return newVideoPlayingObj;
    });

    setIsLoading(false);
  };

  // this useEffect checks if the players have a time divergence and resyncs if needed.
  useEffect(() => {
    if (playerCurrentTimes) {
      checkTimeDiverge(playerCurrentTimes);
    }
  }, [playerCurrentTimes]);

  const checkTimeDiverge = (currentPlayerTimes: CurrentTimeByPosition) => {
    // check diversion between main and zed cams
    let allCamsFinishedSeeking = false;

    if (
      mainCamPosition &&
      dualPlayersFinishedSeeking.current[mainCamPosition] &&
      activeZedPosition &&
      dualPlayersFinishedSeeking.current[activeZedPosition]
    ) {
      allCamsFinishedSeeking = true;
    }

    if (allCamsFinishedSeeking && mainCamPosition && currentZedPosition.current) {
      const currentMainTime = currentPlayerTimes[mainCamPosition];
      const currentZedTime = currentPlayerTimes[currentZedPosition.current];

      if (currentMainTime && currentZedTime) {
        const timeDiff = Math.abs(
          currentMainTime.getTime() / 1000 - currentZedTime.getTime() / 1000
        );

        // if the diff is greater than 2s, resync the times
        if (timeDiff > 2) {
          if (currentMainTime > currentZedTime) {
            // update main time to earlier zed time
            updateRelativeVideoStartTimes(currentZedTime.valueOf(), mainCamPosition);
          } else {
            updateRelativeVideoStartTimes(currentMainTime.valueOf(), currentZedPosition.current);
          }
        }
      }
    }
  };

  /**
   * @name handleTimeUpdated - called by VideoJS players, fires on every second of the video playing.
   * @param {number} seconds - current seconds from start of video
   * @param {string} position - the camera for which we are updating the time for
   * @returns null
   */
  const handleTimeUpdated = (seconds: number, position: CameraPosition) => {
    // if we're playing live video, just set the current time to now
    if (shouldPlayLiveVideo.current) {
      setVideoCurrentTime(moment().toDate());
      return;
    }

    // if we're passing in seconds, then these should be set.
    const currentVideoSegmentIndexForPosition =
      position === mainCamPosition
        ? currentSphericalSegmentIdx.current
        : currentZedSegmentIdx.current;
    const currentVideoUrlIndexForPosition =
      position === mainCamPosition ? activeSphericalUrlIndex.current : activeZedUrlIndex.current;

    if (
      currentVideoSegmentIndexForPosition === undefined ||
      currentVideoUrlIndexForPosition === undefined
    ) {
      setVideoPlaying((prevVideoPlaying) => {
        const newVideoPlayingObj = {
          ...prevVideoPlaying,
          [position]: false,
        };
        return newVideoPlayingObj;
      });
      return;
    }

    const videoSegmentsForCamera = videoSegmentsRef.current[position];
    if (!videoSegmentsForCamera) {
      return;
    }

    const currentCameraSegment = videoSegmentsForCamera[currentVideoSegmentIndexForPosition];
    const videoSegmentUrlsForCamera = videoSegmentUrls.current[position];

    if (currentCameraSegment) {
      const urls = videoSegmentUrlsForCamera?.[currentCameraSegment.start];

      if (urls === undefined) {
        return;
      }

      const url = urls[currentVideoUrlIndexForPosition];
      const currentTime = moment(url.window.from).add(Math.round(seconds), "seconds").toDate();

      // check if other players have video starting at this time.
      // checkPlayersForVideoSegment(currentTime.valueOf());

      if (position === mainCamPosition) {
        // check if the current time is at the end of the current URL window
        if (currentTime.valueOf() >= url.window.to) {
          /* 
        we're at the end of the url window, we need to do one of the following:
        1. move to the next url in this segment 
        2. move to the next segment
        3. stop playing video for the selected date because we're at the last second of video for the day
        */
          const nextUrl = urls[currentVideoUrlIndexForPosition + 1];
          const nextSegment = videoSegmentsForCamera[currentVideoSegmentIndexForPosition + 1];

          if (nextUrl) {
            updateVideoPlayer(nextUrl.window.from, position, currentCameraSegment);
          } else if (nextSegment) {
            // don't update to next segment automatically?
            updateCurrentVideoSegment(currentVideoSegmentIndexForPosition + 1, position);
          }
        }
        setVideoCurrentTime(currentTime);
      }

      setPlayerCurrentTimes((prevCurrentTimes) => {
        return {
          ...prevCurrentTimes,
          [position]: currentTime,
        };
      });
    }
  };

  // pausing/play ALL players
  const handlePauseResume = (shouldPlay: boolean, position?: string) => {
    if (shouldPlay && !checkSafeResume()) {
      return;
    }
    // toggle all players to this state
    if (!position) {
      setVideoPlaying((prevVideoPlaying) => {
        const newVideoPlayingObj: VideoStatusByCamera = {};
        Object.keys(prevVideoPlaying).forEach((position) => {
          // check that urls are set for diff cameras before playing
          if (position === mainCamPosition && currentSphericalUrl.current) {
            newVideoPlayingObj[position] = shouldPlay;
          }
          if (position !== mainCamPosition && currentZedUrl.current) {
            newVideoPlayingObj[position] = shouldPlay;
          }
        });

        return newVideoPlayingObj;
      });
    } else {
      setVideoPlaying((prevVideoPlaying) => {
        const newVideoPlayingObj = {
          ...prevVideoPlaying,
          [position]: shouldPlay,
        };

        return newVideoPlayingObj;
      });
    }
  };

  const findSegmentForTime = (
    time: number,
    position: CameraPosition
  ): DozerVideoSegment | undefined => {
    const segmentsInCamera = videoSegmentsRef.current[position];

    if (segmentsInCamera?.length) {
      const segmentForTime = segmentsInCamera.find(
        (segment) => segment.start <= time && segment.end >= time
      );

      return segmentForTime;
    }
  };

  const handlePreviousOnClick = () => {
    if (currentSphericalSegmentIdx.current === undefined) {
      return;
    }

    if (activeSphericalUrlIndex.current === undefined) {
      return;
    }

    if (!mainCamPosition) {
      return;
    }
    const videoSegments = videoSegmentsRef.current[mainCamPosition];
    if (videoSegments === undefined) {
      return;
    }

    const currentSegment = videoSegments[currentSphericalSegmentIdx.current];
    const videoUrlsForCamera = videoSegmentUrls.current[mainCamPosition];

    if (currentSegment && videoUrlsForCamera?.hasOwnProperty(currentSegment.start)) {
      const hasPreviousSegment = currentSphericalSegmentIdx.current > 0;

      if (hasPreviousSegment) {
        console.log(`there is a previous segment, jumping...`);
        updateCurrentVideoSegment(currentSphericalSegmentIdx.current - 1, mainCamPosition);
      } else {
        console.log(`no previous url, no previous segment, we're at the beginning of the road`);
      }
    } else {
      // only call this once if videoSegmentUrls aren't set yet. Don't call on every time update.
      verifyVideoSegmentUrlsPresent(mainCamPosition, currentSegment);
    }
  };

  const handleSkipBackOnClick = () => {
    if (currentSphericalSegmentIdx.current === undefined) {
      return;
    }

    if (activeSphericalUrlIndex.current === undefined) {
      return;
    }

    if (videoCurrentTime === undefined) {
      return;
    }

    if (!mainCamPosition) {
      return;
    }

    const currentSegmentIndexForCamera = currentSphericalSegmentIdx.current;
    if (currentSegmentIndexForCamera === undefined) {
      return;
    }

    const currentSegment =
      videoSegmentsRef.current[mainCamPosition]?.[currentSegmentIndexForCamera];
    const activeUrlIndexForCamera = activeSphericalUrlIndex.current;

    if (activeUrlIndexForCamera === undefined || !currentSegment) {
      return;
    }

    const activeUrl =
      videoSegmentUrls.current[mainCamPosition]?.[currentSegment.start][activeUrlIndexForCamera];

    if (videoCurrentTime && activeUrl) {
      const newTime = Math.max(videoCurrentTime.valueOf() - 15000, activeUrl.window.from);

      if (currentZedPosition.current && currentSphericalUrl.current) {
        dualPlayersFinishedSeeking.current = {
          [currentZedPosition.current]: false,
          [mainCamPosition!]: false,
        };

        const zedCurrSegment = findSegmentForTime(newTime, currentZedPosition.current);

        // also update the zed to the new time
        if (zedCurrSegment) {
          updateVideoPlayer(newTime, currentZedPosition.current, zedCurrSegment);
        } else {
          clearUrlForPosition(currentZedPosition.current);
        }
      }

      updateVideoPlayer(newTime, mainCamPosition, currentSegment);
    }
  };

  const handleSkipForwardOnClick = () => {
    if (currentSphericalSegmentIdx.current === undefined) {
      return;
    }

    if (activeSphericalUrlIndex.current === undefined) {
      return;
    }

    if (videoCurrentTime === undefined) {
      return;
    }

    const currentSegmentIndexForCamera = currentSphericalSegmentIdx.current;

    if (currentSegmentIndexForCamera === undefined) {
      return;
    }

    if (!mainCamPosition) {
      return;
    }

    const currentSegment =
      videoSegmentsRef.current[mainCamPosition]?.[currentSegmentIndexForCamera];
    const activeUrlIndexForCamera = activeSphericalUrlIndex.current;

    if (activeUrlIndexForCamera === undefined || !currentSegment) {
      return;
    }

    const activeUrl =
      videoSegmentUrls.current[mainCamPosition]?.[currentSegment.start][activeUrlIndexForCamera];

    if (videoCurrentTime && activeUrl) {
      const newTime = Math.min(videoCurrentTime.valueOf() + 15000, activeUrl.window.to);

      // if we are skipping both players forward, we need to reset the seeking times to false,
      // otherwise the function that syncs up the player times to the earliest time won't let
      // us skip forward. It will keep resyncing to the previous time.
      if (currentZedPosition.current && currentSphericalUrl.current) {
        dualPlayersFinishedSeeking.current = {
          [currentZedPosition.current]: false,
          [mainCamPosition!]: false,
        };

        const zedCurrSegment = findSegmentForTime(newTime, currentZedPosition.current);

        // also update the zed to the new time
        if (zedCurrSegment) {
          updateVideoPlayer(newTime, currentZedPosition.current, zedCurrSegment);
        } else {
          clearUrlForPosition(currentZedPosition.current);
        }
      }

      updateVideoPlayer(newTime, mainCamPosition, currentSegment);
    }
  };

  const handleNextOnClick = () => {
    if (currentSphericalSegmentIdx.current === undefined) {
      return;
    }

    if (activeSphericalUrlIndex.current === undefined) {
      return;
    }

    if (!mainCamPosition) {
      return;
    }

    const currentSegmentIndexForCamera = currentSphericalSegmentIdx.current;
    let currentSegment;

    currentSegment = videoSegmentsRef.current[mainCamPosition]?.[currentSegmentIndexForCamera];
    const videoUrlsForCamera = videoSegmentUrls.current[mainCamPosition];

    if (currentSegment && videoUrlsForCamera?.hasOwnProperty(currentSegment.start)) {
      const nextSegmentIdx = currentSegmentIndexForCamera + 1;
      const nextSegment = videoSegmentsRef.current?.[mainCamPosition]?.[nextSegmentIdx];

      if (nextSegment) {
        updateCurrentVideoSegment(nextSegmentIdx, mainCamPosition);
      }
    } else {
      // only call this once if videoSegmentUrls aren't set yet. Don't call on every time update.
      if (currentSegment) {
        verifyVideoSegmentUrlsPresent(mainCamPosition, currentSegment);
      }
    }
  };

  // The slider can currently only be selected from the main (spherical) cam
  const onSliderSelection = async (selectionTime: number, segment: DozerVideoSegment) => {
    // if slider is clicked, live video should be disabled.
    shouldPlayLiveVideo.current = false;

    if (selectionTime === undefined) {
      return;
    }

    if (!mainCamPosition) {
      return;
    }

    // TODO: set loading state
    // check all cameras and update segments accordingly
    const idxOfNewSegment = videoSegmentsRef.current[mainCamPosition]?.findIndex(
      (seg) => seg.start === segment.start
    );

    if (idxOfNewSegment !== undefined) {
      updateCurrentVideoSegment(idxOfNewSegment, mainCamPosition, selectionTime);

      if (activeZedPosition) {
        clearUrlForPosition(activeZedPosition);
      }
    }
  };

  /**
   * This useEffect updates the video controls according to the video time
   * It will disable/enable skipping forward/backward depending on the segments
   * Only enabled for main (spherical) cam
   */
  useEffect(() => {
    if (activeSphericalUrlIndex.current === undefined) {
      return;
    }

    if (!videoCurrentTime) {
      return;
    }

    if (!mainCamPosition) {
      return;
    }

    const currentSegmentIdx = currentSphericalSegmentIdx.current;
    const segmentsForCam = videoSegmentsRef.current[mainCamPosition];

    if (!segmentsForCam || !currentSegmentIdx) {
      return;
    }

    const currentSegment = segmentsForCam[currentSegmentIdx];
    const videoUrlsForCamera = videoSegmentUrls.current[mainCamPosition];

    if (currentSegment && videoUrlsForCamera?.hasOwnProperty(currentSegment.start)) {
      const hasNextSegment = segmentsForCam.length - 1 > currentSegmentIdx;
      const hasPreviousSegment = segmentsForCam.length > 1 && currentSegmentIdx > 0;

      // check if the video segment urls can accomodate 15s forward
      const urls = videoUrlsForCamera[currentSegment.start];
      const currTimeForPosition = videoCurrentTime;

      let canSkipForward = false;
      let canSkipBackward = false;
      if (currTimeForPosition) {
        canSkipForward =
          _.findIndex(urls, (value) => {
            return value.window.to - currTimeForPosition.valueOf() >= 15_000;
          }) > -1;
        canSkipBackward =
          _.findIndex(urls, (value) => {
            return currTimeForPosition.valueOf() - value.window.from >= 15_000;
          }) > -1;
      }

      setCanGoNext(hasNextSegment);
      setCanGoBack(hasPreviousSegment);
      setCanSkipForward(canSkipForward);
      setCanSkipBackward(canSkipBackward);
    }
  }, [videoCurrentTime]);

  const sphericalVideoUrl: VideoUrl | undefined = currentSphericalUrl.current;

  const playerPositionStyles = {
    largePlayerStyle: {
      position: "relative",
      height: "100%",
      width: "100%",
    },
    smallPlayerStyle: {
      position: "absolute",
      top: 0,
      right: 0,
      // height: "300px",
      width: "400px",
    },
  };

  // Main Camera
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", maxWidth: "95%", margin: "auto" }}>
        <Suspense key={mainCamPosition}>
          <Grid container direction="column">
            {/* 
          {mainCamPosition && playerCurrentTimes[mainCamPosition] && (
            <div>Main Cam: {moment(playerCurrentTimes[mainCamPosition]).format("hh:mm:ss")}</div>
          )}
          {activeZedPosition && playerCurrentTimes[activeZedPosition] && (
            <div>Zed Cam:{moment(playerCurrentTimes[activeZedPosition]).format("hh:mm:ss")}</div>
          )} */}
            <Grid
              container
              style={{
                position: "relative",
                background: "black",
                height: "auto",
                width: "100%",
                maxWidth: "1600px",
              }}
            >
              <Grid
                item
                sx={{ position: "relative" }}
                xs={12}
                // xs={showHistoryPanel ? 9 : 12}
                // style={{ position: "relative", height: "100%" }}
                onMouseEnter={() => {
                  // only allow playing/pausing if there's video url to play
                  if (sphericalVideoUrl || activeZedVideoUrl) {
                    setVideoControlsHidden(false);
                  }
                }}
                onMouseLeave={() => {
                  setVideoControlsHidden(true);
                }}
              >
                {sphericalVideoUrl && !isLoading && shouldLoadVideo && (
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        background: activeZedPosition ? "rgba(92, 99, 116, 0.4)" : "transparent",
                        top: 0,
                        zIndex: 100,
                        width: "100%",
                        height: "150px",
                      }}
                    >
                      <VideoOverlay
                        videoTimeStamp={videoCurrentTime}
                        isLive={isLive}
                        showGoLiveButton={false}
                        show={isLive ? liveVideoUrl !== undefined : true}
                        selectedAssetEvent={currentZedEvent}
                        goLiveOnClick={handleGoLive}
                      />
                    </div>

                    <VideoJS
                      onTimeUpdated={handleTimeUpdated}
                      isVideoPlaying={isVideoPlaying}
                      handlePauseResume={handlePauseResume}
                      seekToTime={videoStartTimeMain}
                      onVideoEvent={(event) => {
                        handleVideoEvent(event, liveVideoUrl, mainCamPosition!);
                      }}
                      isLive={isLive}
                      videoUrl={sphericalVideoUrl}
                      cameraPosition={mainCamPosition}
                      // aspectRatio={"16:9"}
                      isVideoLoading={isVideoLoading}
                      setIsVideoLoading={setIsVideoLoading}
                      refetchVideoSegments={fetchAllVideoSegments}
                    />
                  </div>
                )}

                {!isLive && !sphericalVideoUrl && !isLoading && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "100%",
                      height: "600px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: colors.dozer.gray,
                      }}
                    >
                      Video Not Available
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors.dozer.yellow,
                      }}
                    >
                      Please Select Another Date Below
                    </Typography>
                  </Grid>
                )}

                {isLoading && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "100%",
                      height: "600px",
                    }}
                  >
                    <LoadingSpinner />
                  </Grid>
                )}

                {/* Don't show these buttons if live video is on. */}
                {!isLive && (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      bottom: "1rem",
                    }}
                  >
                    <VideoControls
                      hidden={videoControlsHidden}
                      isPlaying={
                        (mainCamPosition && isVideoPlaying[mainCamPosition]) ||
                        (activeZedPosition && isVideoPlaying[activeZedPosition]) ||
                        false
                      }
                      canGoNext={canGoNext}
                      canGoPrevious={canGoBack}
                      canPlay={true}
                      canSkipBack={canSkipBackward}
                      canSkipForward={canSkipForward}
                      previousOnClick={handlePreviousOnClick}
                      skipBackOnClick={handleSkipBackOnClick}
                      pauseResumeOnClick={handlePauseResume}
                      skipForwardOnCLick={handleSkipForwardOnClick}
                      nextOnClick={handleNextOnClick}
                      position={mainCamPosition!}
                    />
                  </div>
                )}

                {/* Absolute positioned zed video player */}
                {activeZedVideoUrl && activeZedPosition && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 24,
                        left: 24,
                        width: "300px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          border: `1px solid ${colors.dozer.gray.dark}`,
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            position: "relative",
                            background: colors.dozer.gray.dark,
                            width: "100%",
                            height: 28,
                          }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item sx={{ marginLeft: "5px" }}>
                            <Typography color={colors.dozer.white}>{activeZedPosition}</Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={() => {
                                clearUrlForPosition(activeZedPosition);
                              }}
                              sx={{ minWidth: "unset" }}
                            >
                              <CloseIcon fill={colors.dozer.gray.medium} />
                            </Button>
                          </Grid>
                        </Grid>
                        <VideoJS
                          onTimeUpdated={handleTimeUpdated}
                          isVideoPlaying={isVideoPlaying}
                          handlePauseResume={handlePauseResume}
                          seekToTime={videoStartTimeZed}
                          onVideoEvent={(event) => {
                            handleVideoEvent(event, liveVideoUrl, activeZedPosition);
                          }}
                          isLive={false}
                          videoUrl={activeZedVideoUrl}
                          cameraPosition={activeZedPosition}
                          // aspectRatio={"21:9"}
                          isVideoLoading={isVideoLoading}
                          setIsVideoLoading={setIsVideoLoading}
                          refetchVideoSegments={fetchAllVideoSegments}
                        />
                      </div>
                      {/* <Button onClick={() => setSwapPlayerPositions(true)}>Expand</Button> */}
                    </div>
                  </div>
                )}
              </Grid>
              {showHistoryPanel && (
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "240px",
                    right: 0,
                    top: 0,
                    zIndex: 150,
                  }}
                >
                  <ActivityFeed
                    timelineEvents={zedEvents}
                    onVideoPlayer
                    onClose={() => setShowHistoryPanel(false)}
                    onEventClick={handleEventClick}
                    eventsLoading={isLoading}
                  />
                </div>
              )}
            </Grid>
            <Grid
              container
              display="flex"
              alignItems="center"
              padding={1}
              style={{ maxWidth: "1600px" }}
            >
              <Grid
                container
                item
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                xs={1}
              >
                <CalendarPicker
                  selected={selectedDate}
                  setSelected={(date) => {
                    if (date) {
                      setSelectedDate(date);
                      setIsLive(false);
                      shouldPlayLiveVideo.current = false; // need to do this as well since Video Player reads memoized isLive value
                      // we need to clear the zed event and reset the param time
                      updateUrlParams({ videoCurrentTime: undefined, eventId: undefined });
                    }
                  }}
                  dateIndicators={dateIndicators}
                  loadingDateIndicators={loadingDateIndicators}
                  onMonthChanged={(month) => {
                    fetchDateIndicators(month);
                  }}
                />
                <Grid item>
                  <Typography variant="h6" sx={{ textTransform: "uppercase" }}>
                    {moment(selectedDate).format("MMM DD")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={10.5}>
                <VideoSlider
                  selectedDate={selectedDate}
                  segments={videoSegments[mainCamPosition!]}
                  videoCurrentTime={videoCurrentTime}
                  onSliderSelection={onSliderSelection}
                  position={mainCamPosition}
                />
              </Grid>
              <Grid item xs={0.5}>
                <Button onClick={() => setShowHistoryPanel(!showHistoryPanel)}>
                  <ListDetailViewIcon
                    fill={showHistoryPanel ? colors.dozer.yellow : colors.dozer.gray.medium}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Suspense>
      </div>
    </div>
  );
};

export default AssetVideoFeed;

AssetVideoFeed.propTypes = {
  videoCurrentTime: PropTypes.string,
  liveVideoUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  currentAssetEvent: PropTypes.object,
  navigateToLiveVideo: PropTypes.func,
  calendarDate: PropTypes.string,
  showHistoryPanel: PropTypes.bool,
  eventSummaries: PropTypes.object,
  timelineEvents: PropTypes.array,
  riskLevels: PropTypes.object,
  asset: PropTypes.object,
};
