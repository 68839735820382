// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Timeline context
import { useTimeline } from "examples/Timeline/context";
import colors from "assets/theme/base/colors";

// Custom styles for the TimelineItem
import {
  dozerTimelineIcon,
  timelineItem,
  timelineItemIcon,
} from "examples/Timeline/TimelineItem/styles";
import { AssetTypeToImage } from "layouts/dashboards/default/data/assets";
import { Grid, SvgIcon } from "@mui/material";

import { Image } from "mui-image";

function DozerTimelineItem({
  color,
  icon,
  image,
  title,
  dateTime,
  description,
  badges,
  lastItem,
  onClick,
  darkMode,
}) {
  const isDark = useTimeline();

  const renderBadges =
    badges.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`;

          return (
            <SoftBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              <SoftBadge color={color} size="xs" badgeContent={badge} container />
            </SoftBox>
          );
        })
      : null;

  return (
    <SoftBox
      position="relative"
      sx={(theme) => timelineItem(theme, { lastItem }, isDark)}
      bgColor={isDark ? colors.dozer.gray.dark : colors.white.main}
      onClick={onClick}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        bgColor={isDark ? colors.dozer.gray.dark : colors.white.main}
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="0.05rem"
        zIndex={2}
      >
        {/* <SoftBox component="img" sx={(theme) => dozerTimelineIcon(theme, { color })} src={image.icon} /> */}

        <SvgIcon sx={(theme) => dozerTimelineIcon(theme, { color })}>{icon}</SvgIcon>
      </SoftBox>
      <Grid container ml={5.75} sx={{ width: "auto" }}>
        <Grid item align="left" sx={{ width: "100%", marginTop: "0.5rem" }}>
          <SoftBox display="flex" alignItems="center">
            <SoftTypography
              width="100%"
              variant="button"
              fontWeight="medium"
              color={isDark ? "white" : "dark"}
            >
              {title}
            </SoftTypography>
            <SoftTypography
              variant="caption"
              fontWeight="medium"
              color={isDark ? "white" : "dark"}
              sx={{ textWrap: "wrap", textAlign: "end" }}
            >
              {dateTime}
            </SoftTypography>
          </SoftBox>

          <SoftBox mb={1.5}>
            {description && !isDark ? (
              <SoftTypography
                variant="caption"
                fontWeight="regular"
                color={isDark ? "white" : "dark"}
              >
                {description}
              </SoftTypography>
            ) : null}
          </SoftBox>

          <SoftBox mb={1.5} width={"30%"}>
            {image ? <Image src={image} /> : null}
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Setting default values for the props of TimelineItem
DozerTimelineItem.defaultProps = {
  color: "info",
  badges: [],
  lastItem: false,
  description: "",
  onClick: undefined,
};

// Typechecking props for the TimelineItem
DozerTimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.any,
  image: PropTypes.any,
  title: PropTypes.any.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool,
  onClick: () => {},
  darkMode: PropTypes.bool,
};

export default DozerTimelineItem;
