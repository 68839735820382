import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import { Grid, Tabs, Tab, Box, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EventsOverview from "components/EventsOverview";
import AssetVideoFeed from "components/AssetVideoFeed";
import PropTypes from "prop-types";
import { getAssetOverview, getAsset } from "data/api/asset";
import { useParams, NavLink, useSearchParams, useNavigate } from "react-router-dom";
import colors from "../../assets/theme/base/colors";
import _ from "lodash";
import { CameraIcon } from "../../assets/Icons";
import moment from "moment";
import { Asset, EventsSummaries, AssetEvent, RiskLevels } from "data/models/models";

const AssetOverview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [asset, setAsset] = useState<Asset | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>();
  const [eventSummaries, setEventSummaries] = useState<EventsSummaries>();
  const [timelineEvents, setTimelineEvents] = useState<AssetEvent[]>([]);
  const [riskLevels, setRiskLevels] = useState<RiskLevels>({ day: [], month: [], week: [] });
  const [shouldLoadVideo, setShouldLoadVideo] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const params = useParams();
  const { assetId } = params;

  const fetchAsset = async () => {
    if (!assetId) {
      return;
    }

    try {
      setIsLoading(true);
      const assetResponse = await getAssetOverview(assetId);

      if (assetResponse) {
        const {
          eventsSummaries: summaries,
          recentEvents: events,
          riskLevels: levels,
        } = assetResponse;

        setEventSummaries(summaries);
        setTimelineEvents(events);
        setRiskLevels(levels);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching asset", error);
      setIsLoading(false);
    }
  };

  const fetchAssetData = async () => {
    if (!assetId) {
      return;
    }

    try {
      setIsLoading(true);
      const assetResponse = await getAsset(assetId);

      if (assetResponse) {
        setAsset(assetResponse);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching asset", error);
      setIsLoading(false);
    }
  };

  const onTimelineEventClick = (event: AssetEvent) => {
    setSearchParams(
      [
        ["event", event.id],
        ["videoTimestamp", moment(event.streamTimestamp).toISOString(true)],
      ],
      {
        replace: true,
      }
    );
    setSelectedTab(1);
  };

  useEffect(() => {
    if (asset) {
      const zedEventId = searchParams.get("event");
      const existingTimestamp = searchParams.get("videoTimestamp");

      if (zedEventId || existingTimestamp) {
        setSelectedTab(1);
      } else {
        setSelectedTab(0);
      }
    }
  }, [asset]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchAssetData();
      await fetchAsset();
    };
    if (assetId) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    // we need to trigger the play() event after we have switched tabs to the video player tab. Otherwise the player will throw an error and won't start playing.
    if (selectedTab === 1) {
      setShouldLoadVideo(true);
      navigate(`/assets/video/${assetId}?${searchParams}`);
    }

    if (selectedTab === 0) {
      // ensures video component doesn't render / clears its state
      setShouldLoadVideo(false);
      navigate(`/assets/overview/${assetId}`);
    }
  }, [selectedTab]);

  const handleSelectTab = (_e: React.SyntheticEvent, value: number) => setSelectedTab(value);

  return (
    <DashboardLayout pageTitle="Assets">
      {/* @ts-ignore */}
      <SoftBox pt={3} bgColor="white" sx={{ maxWidth: "1080px" }}>
        <Grid container direction="column" sx={{ maxWidth: "100%", height: "100%" }}>
          <Grid container sx={{ height: "100%" }}>
            <Grid container item xs={12} alignItems="center" sx={{ paddingLeft: "15px" }}>
              <NavLink to={`/assets`} style={{ display: "flex" }}>
                <ArrowBackIcon sx={{ fill: colors.dozer.gray.light }} fontSize="large" />
              </NavLink>
              {/* @ts-ignore */}
              <Typography
                variant="h4"
                fontWeight="light"
                color={colors.dozer.gray.dark}
                ml={1.5}
                xs={12}
              >
                {asset?.nickName}
              </Typography>
              <Typography
                variant="caption"
                textTransform="uppercase"
                fontWeight="light"
                color={colors.dozer.gray.medium}
                ml={1.5}
              >
                {asset && asset.year} {asset && asset.model} {asset && asset.typeInfo.type}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              xs={12}
              sx={{ minHeight: "600px", maxWidth: "1080px" }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleSelectTab}
                sx={{
                  mt: 1,
                  ml: 7,
                  width: "fit-content",
                  backgroundColor: "transparent !important",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab label="Overview" value={0} sx={{ mr: 3 }} />
                <Tab
                  label="Video Feed"
                  iconPosition="end"
                  icon={
                    <CameraIcon
                      fill={selectedTab === 1 ? colors.dozer.gray.dark : colors.dozer.gray.light}
                    />
                  }
                  value={1}
                />
              </Tabs>
              <Box ml={8} display={selectedTab === 0 ? "block" : "none"}>
                <EventsOverview
                  timelineEvents={timelineEvents}
                  eventSummaries={eventSummaries}
                  riskLevels={riskLevels}
                  handleTimelineEventClick={onTimelineEventClick}
                  eventsLoading={isLoading}
                />
              </Box>
              <Box flexGrow={1} display={selectedTab === 1 ? "block" : "none"}>
                {shouldLoadVideo && asset && (
                  <AssetVideoFeed
                    eventSummaries={eventSummaries}
                    timelineEvents={timelineEvents}
                    riskLevels={riskLevels}
                    asset={asset}
                    shouldLoadVideo={shouldLoadVideo}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

AssetOverview.propTypes = {
  videoTimestamp: PropTypes.string,
  tab: PropTypes.number,
};

export default AssetOverview;
