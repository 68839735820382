// Authentication/global stuff
//    Sign up/Login
import { toUpperCase } from "../api/util";

export type UserSession = {
  user: User;
};

export type User = {
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  status: UserStatus;
  userId: string;
  organizationId: string;
};

export type SigninResult = {
  user?: User;
  status: number;
  message?: string;
};

export type SigninResponse = {
  user: User;
  token: string;
};

export type SignUpRequest = {
  email: string;
  firstName: string;
  lastName: string;
  userAuthId: string;
  pictureUrl: string;
};

export type CreateUserRequest = {
  email: string;
  givenName: string;
  familyName: string;
  pictureUrl: string;
  authId: string;
  organizationId: string;
};

export type WebAppUser = User & {
  idToken: string;
};

export enum UserRole {
  DozerAdmin = "DOZER_ADMIN",
  DozerDeveloper = "DOZER_DEVELOPER",
  DozerEmployee = "DOZER_EMPLOYEE",
  OrgAdmin = "ORG_ADMIN",
  OrgMember = "ORG_MEMBER",
}

export enum UserStatus {
  ACTIVE,
  INVITED,
  RESET_PW,
  UNKNOWN,
}

export enum JobsiteStatus {
  Active,
  Inactive,
}

// Jobsites
export type Jobsites = {
  jobesiteSummaries: JobsiteSummary[];
};

// Jobsite
//     View job site - overview
export type JobsiteOverview = {
  jobsite: JobsiteMetadata;
  activeEquipment: AssetCount[];
  recentEvents: AssetEvent[];
  eventsSummaries: EventsSummaries;
  riskLevels: RiskLevels;
};

//    View job site - assets
export type JobsiteAssets = {
  assets: Asset[];
};

//    View job site - activity
export type JobsiteActivity = {
  recentEvents: AssetEvent[];
  riskLevels: RiskLevels;
  incidents: AssetEvent[];
};

// Assets----
//    View assets
export type Assets = {
  assets: AssetSummary[];
};

//    View asset - overview
export type AssetOverview = {
  recentEvents: AssetEvent[];
  eventsSummaries: EventsSummaries;
  riskLevels: RiskLevels;
};

//    View asset - camera feeds, camera history
export type AssetVideoFeed = {
  onDemandUrl: ArchivedVideoUrl | null;
  liveUrl: ArchivedVideoUrl | null;
};

export type VideoUrl = {
  url: string;
  expiration: number;
  videoType: string;
  isLive: boolean;
};

export type ArchivedVideoUrl = VideoUrl & {
  window: TimeWindow;
};

export type TimeWindow = {
  from: number;
  to: number;
};

export type MonthlyVideoStatuses = {
  date: string;
  status: string;
};

// Dashboard
//    View Dashboard

export type DashboardData = {
  idleEquipment: AssetCount[];
  activeEquipment: AssetCount[];
  activeJobsites: JobsiteMetadata[];
  incidentsToday: number;
  recentEvents: AssetEvent[];
};

// Login
export type SignInParams = {
  email: string;
  password: string;
};

export type SignUpParams = {
  email: string;
  inviteToken: string;
  firstName: string;
  lastName: string;
  password: string;
};

//  -----Common stuff------
export type AssetCount = {
  count: number;
  type: AssetTypeInfo;
};

export type Asset = {
  assetId: string;
  location: GeoLocation | string | null;
  isActive: boolean;
  model: string | null;
  nickName: string | null;
  snsTopicArn: string | null;
  year?: number;
  typeInfo: AssetTypeInfo;
  videoStreamInfo: StreamInfo[];
};

export type StreamInfo = {
  position: CameraPosition;
  streamStatus: string;
  kinesisStreamArn: string;
  streamId?: string;
};

export type AssetTypeInfo = {
  type: AssetType;
  iconUrl: string;
};

export enum AssetType {
  Bulldozer = "BULLDOZER",
  Excavator = "EXCAVATOR",
  Backhoe = "BACKHOE",
  Front_Loader = "FRONT_LOADER",
  Mini_Excavator = "MINI_EXCAVATOR",
  Pallet_Truck = "PALLET_TRUCK",
  Forklift = "FORKLIFT",
  Dump_Truck = "DUMP_TRUCK",
  Grader = "GRADER",
  Trencher = "TRENCHER",
  Crane = "CRANE",
  Crawler_Dozer = "CRAWLER_DOZER",
  Conveyor = "CONVEYOR",
  Compactor = "COMPACTOR",
  Truck = "TRUCK",
  Vehicle = "VEHICLE",
  Unknown = "UNKNOWN",
}

export type JobsiteMetadata = {
  id: string;
  name: string | null;
  imageUrl: string | null;
  address: GeoLocation | string | null;
};

export type GeoLocation = {
  city?: string;
  state?: string;
};

export type AssetEvent = {
  id: string;
  assetId: string | null;
  clipMp4Url: string | null;
  gifUrl: string | null;
  thumbnailUrl: string | null;
  webpThumbnailUrl: string | null;
  title: string;
  subtitle: string | null;
  eventTimestamp: number;
  eventEndTimestamp: number | null;
  streamTimestamp: number;
  type: AssetEventType;
  level: AssetEventLevelType;
  location: GeoLocation | string | null;
  jobsite: JobsiteMetadata | null;
  streamId: string;
};

export enum AssetEventType {
  HumanBodyProximity = 0, // person close to equipment
  HumanAppendageProximity = 1, // person close to equipment appendage
  VehicleBodyProximity = 2, // another vehicle/equipment close to equipment
  VehicleAppendageProximity = 3, // another vehicle close to equipment appendage
  BarrierBodyProximity = 4, // fence, highway barrier, k-rail close to equipment (Placeholder)
  BarrierAppendageProximity = 5, // barrier close to equipment appendage (Placeholder)
  BuildingBodyProximity = 6, // building close to equipment (Placeholder)
  BuildingAppendageProximity = 7, // building close to equipment appendage (Placeholder)
  StreetFurnitureBodyProximity = 8, // Street furniture close to equipment
  StreetFurnitureAppendageProximity = 9, // Street furniture close to equipment appendage
  DropoffProximity = 20, // steep dropoff/trench/cliff close equipment (Placeholder)
  UnsafeMaterialProximity = 21, // proximity of human/vehicle/etc during material emptying (Placeholder)

  // Image Recognition Type Events
  HumanActivity = 30, // All people in the 360 image
  IntruderActivity = 31, // person detected outside of normal operating hours

  // IMU Type Events
  ExcessivePitchRoll = 40, // vehicle exceeding pitch/roll angle
  Rollover = 41, // vehicle has rolled/tipped over
  EquipmentFailure = 42, // bucket/boom/blade failure/free-fall

  // GPS Type Events
  GeofenceExit = 50, // vehicle exited geofence area
  GeofenceEntry = 51, // vehicle entered geofence area
  ExcessiveSpeed = 52, // vehicle above set speed limit

  Started = 1003,
  Stopped = 1002,
  JobsiteChange = 1001,
  SensorLog = 1000,
  Unknown = 999,
}

/**
 * FROM BACKEND CODE AS OF 1/21/2024
    HUMAN_BODY_PROXIMITY(0, WARNING), // person close to equipment
    HUMAN_APPENDAGE_PROXIMITY(1, WARNING), // person close to equipment appendage
    VEHICLE_BODY_PROXIMITY(2, WARNING), // another vehicle/equipment close to equipment
    VEHICLE_APPENDAGE_PROXIMITY(3, WARNING), // another vehicle close to equipment appendage
    BARRIER_BODY_PROXIMITY(4, WARNING), // fence, highway barrier, k-rail close to equipment (Placeholder)
    BARRIER_APPENDAGE_PROXIMITY(5, WARNING),  // barrier close to equipment appendage (Placeholder)
    BUILDING_BODY_PROXIMITY(6, WARNING), // building close to equipment (Placeholder)
    BUILDING_APPENDAGE_PROXIMITY(7, WARNING),  // building close to equipment appendage (Placeholder)
    STREET_FURNITURE_BODY_PROXIMITY(8, WARNING), // Street furniture close to equipment
    STREET_FURNITURE_APPENDAGE_PROXIMITY(9, WARNING),  // Street furniture close to equipment appendage
    DROPOFF_PROXIMITY(20, WARNING), // steep dropoff/trench/cliff close equipment (Placeholder)
    UNSAFE_MATERIAL_PROXIMITY(21, WARNING), // proximity of human/vehicle/etc during material emptying (Placeholder)

    // Image Recognition Type Events
    HUMAN_ACTIVITY(30, NORMAL), // All people in the 360 image
    INTRUDER_ACTIVITY(31, WARNING), // person detected outside of normal operating hours

    // IMU Type Events
    EXCESSIVE_PITCH_ROLL(40, WARNING), // vehicle exceeding pitch/roll angle
    ROLLOVER(41, INCIDENT), // vehicle has rolled/tipped over
    EQUIPMENT_FAILURE(42, INCIDENT), // bucket/boom/blade failure/free-fall

    // GPS Type Events
    GEOFENCE_EXIT(50, NORMAL), // vehicle exited geofence area
    GEOFENCE_ENTRY(51, NORMAL), // vehicle entered geofence area
    EXCESSIVE_SPEED(52, WARNING), // vehicle above set speed limit

    STARTED(1003),
    STOPPED(1002),
    JOBSITE_CHANGE(1001),
    SENSOR_LOG(1000),
    UNKNOWN(999);
 */

export enum AssetEventLevel {
  Normal,
  Warning,
  Incident,
  Unknown,
}

export let AssetEventTypeToEventLevel = {
  [AssetEventType.HumanBodyProximity]: AssetEventLevel.Warning,
  [AssetEventType.VehicleBodyProximity]: AssetEventLevel.Warning,
  [AssetEventType.BarrierBodyProximity]: AssetEventLevel.Warning,
  [AssetEventType.BuildingBodyProximity]: AssetEventLevel.Warning,
  [AssetEventType.DropoffProximity]: AssetEventLevel.Warning,
  [AssetEventType.UnsafeMaterialProximity]: AssetEventLevel.Warning,

  [AssetEventType.HumanActivity]: AssetEventLevel.Normal,
  [AssetEventType.IntruderActivity]: AssetEventLevel.Warning,

  [AssetEventType.ExcessivePitchRoll]: AssetEventLevel.Warning,
  [AssetEventType.Rollover]: AssetEventLevel.Incident,
  [AssetEventType.EquipmentFailure]: AssetEventLevel.Incident,

  [AssetEventType.GeofenceExit]: AssetEventLevel.Normal,
  [AssetEventType.GeofenceEntry]: AssetEventLevel.Normal,
  [AssetEventType.ExcessiveSpeed]: AssetEventLevel.Normal,

  [AssetEventType.Started]: AssetEventLevel.Normal,
  [AssetEventType.Stopped]: AssetEventLevel.Normal,
  [AssetEventType.JobsiteChange]: AssetEventLevel.Normal,
  [AssetEventType.Unknown]: AssetEventLevel.Unknown,
};

export enum CameraPosition {
  FRONT = "FRONT",
  FRONT_LEFT = "FRONT_LEFT",
  FRONT_RIGHT = "FRONT_RIGHT",
  REAR = "REAR",
  REAR_LEFT = "REAR_LEFT",
  REAR_RIGHT = "REAR_RIGHT",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  SPHERICAL_CAM = "SPHERICAL_CAM",
  SPHERICAL_180_CAM = "SPHERICAL_180_CAM",
  FIXED_FOV_CAM = "FIXED_FOV_CAM",
  BASE = "BASE",
  UNKNOWN = "UNKNOWN",
}

export enum AssetEventLevelType {
  Normal = "NORMAL",
  Warning = "WARNING",
  Incident = "INCIDENT",
  Unknown = "UNKNOWN",
}

export type JobsiteSummary = {
  metadata: JobsiteMetadata;
  activeEquipment: AssetCount[];
  incidentsToday: number;
  status: JobsiteStatus;
};

export type DataPoint = {
  label: string;
  count: number;
};

export type JobsiteEventsSummary = {
  warnings: number;
  incidents: number;
};

export type EventsSummaries = {
  day: JobsiteEventsSummary;
  week: JobsiteEventsSummary;
  month: JobsiteEventsSummary;
};

export type RiskLevels = {
  day: DataPoint[];
  week: DataPoint[];
  month: DataPoint[];
};

export type Organization = {
  name: string;
};

export type ActiveWindow = {
  type: AssetEventType | null;
  startTimestamp: number | null;
  stopTimestamp: number | null;
};

export type AssetSummary = {
  asset: Asset;
  numIncidents: number;
  numWarnings: number;
  numNearMisses: number;
  activeWindows: ActiveWindow[];
};

export type DozerVideoSegment = {
  start: number;
  end: number;
  numberOfFragments: number;
  startFragment?: string;
  endFragment?: string;
  segmentEvents: AssetEvent[];
  segmentUrls: ArchivedVideoUrl[];
};

export type DozerVideoSegmentByPosition = {
  [key in CameraPosition | string]?: DozerVideoSegment[];
};

export type VideoSegmentUrlsBySegment = {
  [segmentStart: number]: ArchivedVideoUrl[];
};

// accounts for all zed cameras
export type AllCamerasVideoSegmentUrls = {
  [key in CameraPosition | string]?: VideoSegmentUrlsBySegment;
};

export type DozerMonthlyVideoInfo = {
  dayTimestamp: number;
  eventLevels: AssetEventLevelType[];
};

export function assetDisplayName(asset: Asset): string {
  return `${asset.year} ${
    asset.model ? asset.model.toString().split("_").map(toUpperCase).join(" ") : ""
  } ${
    asset.typeInfo.type ? asset.typeInfo.type.toString().split("_").map(toUpperCase).join(" ") : ""
  }`;
}
