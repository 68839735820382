import moment from "moment";
import { AssetEventLevelType, AssetEvent } from "data/models/models";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { CameraIcon } from "assets/images/icons/Icons";
import colors from "assets/theme/base/colors";

type IsLiveBadgeProps = {
  isLive: boolean;
};

const IsLiveBadge = ({ isLive }: IsLiveBadgeProps) => {
  return (
    // <span style={{ textTransform: "uppercase", background: isLive ? "orange" : "gray" }}>live</span>
    // <Chip label="primary" color="primary" />
    <Chip
      label="LIVE"
      variant={isLive ? "filled" : "outlined"}
      sx={{
        height: "auto",
        borderRadius: 1,
        fontWeight: "bold",
        "&.MuiChip-root": {
          border: "unset",
        },
        "&.MuiChip-outlined": {
          backgroundColor: colors.dozer.gray.dark,
        },
        "&.MuiChip-outlined span": {
          color: colors.dozer.gray.light,
        },
        "&.MuiChip-filled": {
          backgroundColor: isLive ? colors.live.main : colors.idle.main,
        },
        "&.MuiChip-filled span": {
          color: isLive ? colors.live.contrastText : colors.idle.contrastText,
        },
        span: {
          padding: "3px 6px 3px 6px",
        },
      }}
    />
  );
};

type VideoOverlayProps = {
  videoTimeStamp: Date | undefined;
  isLive: boolean;
  show: boolean;
  showGoLiveButton: boolean;
  goLiveOnClick: () => void;
  selectedAssetEvent: AssetEvent | undefined;
};

const VideoOverlay = ({
  videoTimeStamp,
  isLive,
  show,
  showGoLiveButton,
  goLiveOnClick,
  selectedAssetEvent,
}: VideoOverlayProps) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "100%",
          display: show ? "flex" : "hidden",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          alignItems="center"
          sx={{
            padding: "18px",
            width: "100%",
          }}
        >
          <Grid container justifyContent="space-between">
            <div>
              <IsLiveBadge isLive={isLive} />
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "12pt",
                  marginLeft: "1.5rem",
                  color: colors.dozer.white,
                  textTransform: "uppercase",
                  opacity: 1,
                  textShadow: "0px 0px 3px rgb(0 0 0 / 50%)",
                }}
              >
                {moment(videoTimeStamp).format("MMM D, YYYY - hh:mm:ss A z")}
              </span>
            </div>
            {showGoLiveButton && (
              <Button
                component="label"
                variant="outlined"
                startIcon={<CameraIcon fill={colors.dozer.yellow} />}
                onClick={() => {
                  if (goLiveOnClick) {
                    goLiveOnClick();
                  }
                }}
              >
                GO LIVE
              </Button>
            )}
          </Grid>
          {selectedAssetEvent && <IncidentOverlay assetEvent={selectedAssetEvent} />}
        </Grid>
      </div>
    </>
  );
};

const IncidentOverlay = ({ assetEvent }: { assetEvent: AssetEvent }) => {
  const assetEventLevelBadgeColor = (level: AssetEventLevelType) => {
    switch (level) {
      case AssetEventLevelType.Incident:
        return colors.alertColors.error.main;
      case AssetEventLevelType.Warning:
        return colors.dozer.yellow;
      case AssetEventLevelType.Normal:
        return colors.dozer.green;
      default:
        return colors.dozer.green;
    }
  };

  if (assetEvent) {
    return (
      <Grid container direction="column" alignItems="center" gap={1}>
        <Grid
          style={{
            background: assetEventLevelBadgeColor(assetEvent.level),
            color: colors.dozer.white,
            padding: "6px",
            border: "1px transparent",
            borderRadius: "5px",
            maxWidth: "max-content",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {assetEvent.level}
        </Grid>
        <Typography
          sx={{
            textTransform: "uppercase",
            color: colors.dozer.white,
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {assetEvent.title}
        </Typography>
      </Grid>
    );
  }
};

export default VideoOverlay;
