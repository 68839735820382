import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import App from "./App";
import PropTypes from "prop-types";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "./context";
import { Auth0ProviderWithNavigate } from "./components/auth0/Auth0ProviderWithNavigate";
import { DozerProvider } from "./context/dozer-context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <DozerProvider>
      <Auth0ProviderWithNavigate>
        <SoftUIControllerProvider>
          <App />
        </SoftUIControllerProvider>
      </Auth0ProviderWithNavigate>
    </DozerProvider>
  </BrowserRouter>
);
